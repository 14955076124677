<template>
    <div>
        <section v-if="projects && projects.length > 0" class="tw-space-y-6 tw-items-center tw-mx-auto tw-max-w-2xl">
            <project-preview v-for="project in projects" :key="project.id" :project="project" />
        </section>

        <section v-else-if="loading" class="tw-space-y-6 tw-items-center tw-mx-auto tw-max-w-2xl">
            <project-preview-skeleton type="sentence, article" v-for="idx in 4" :key="idx" />
        </section>

        <section v-else class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-rounded-sm tw-px-4 tw-py-12">
            <img src="./assets/saved-projects-empty.svg" alt="Empty" class="tw-w-48 tw-h-48" />

            <p class="tw-italic tw-text-gray-800">
                {{ $t('profile.descriptions.no_job_offer_saved') }}
            </p>

            <router-link class="tw-italic tw-text-koderia tw-mt-2 tw-inline-block tw-no-underline hover:tw-underline" :to="{ name: 'project-index', params: { locale } }">
                {{ $t('profile.actions.find_job') }}
            </router-link>
        </section>
    </div>
</template>

<script>
import ProjectPreviewSkeleton from "./../projects/components/project-preview-skeleton-new";
import ProjectPreview from "./../projects/components/project-preview-new";
import {getRouteLocale} from '@web/constants/language'

export default {
    components: {
        ProjectPreviewSkeleton,
        ProjectPreview
    },

    data() {
        return {
            projects: [],
            loading: true
        };
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile;
        },
        locale() {
            return getRouteLocale()
        }
    },
    watch: {
        profile: {
            immediate: true,
            async handler() {
                if (!this.profile) {
                    this.loading = false;
                    return;
                }
                this.loading = true;
                this.projects = await this.$store.dispatch("NEW_PROJECTS/fetchAllProjectsByIds", this.profile.savedProjects);
                this.loading = false;
            }
        }
    }
};
</script>
